import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
  } from '@material-ui/core';

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";


import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const FgtsSimulationSchema = Yup.object().shape({
	bank: Yup.string().required("Required"),
	cpf: Yup.string().required("Required"),
});

const FgtsSimulationModal = ({ open, onClose }) => {
	const classes = useStyles();

	const initialState = {
		bank: "0",
		cpf: "",
	};

	const [fgts, setFgts] = useState(initialState);
	const [proposes, setProposes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleClose = () => {
		onClose();
		setFgts(initialState);
	};

	const handleSimulateFgts = async values => {
		try {
			setIsLoading(true);
			setProposes([]);
			const {data} = await api.get(`/fgts/simulate/${values.cpf}`, {
				data: {
					bankLoginId: values.bank,
				}
			});
			setProposes(data);
			toast.success("Simulação realizada.");
			setIsLoading(false);
		} catch (err) {
			console.log(err?.response);
			setIsLoading(false);
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Simular FGTS
				</DialogTitle>
				<Formik
					initialValues={fgts}
					enableReinitialize={true}
					validationSchema={FgtsSimulationSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSimulateFgts(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<InputLabel id="bank-selection-input-label">
											Banco
										</InputLabel>
										<Field
											as={Select}
											label="Banco"
											name="bank"
											labelId="bank-selection-label"
											id="bank-selection"
											required
										>
											<MenuItem value="0">Facta</MenuItem>
											<MenuItem value="1">Pine</MenuItem>
										</Field>
									</FormControl>
									<Field
										as={TextField}
										label="CPF"
										name="cpf"
										error={touched.cpf && Boolean(errors.cpf)}
										helperText={touched.cpf && errors.cpf}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								{proposes && proposes.length > 0 && (
									<Paper
										className={classes.mainPaper}
										variant="outlined"
									>
										<Table size="small">
										<TableHead>
											<TableRow>
											<TableCell align="center">Tabela</TableCell>
											<TableCell align="center">Valor Líquido</TableCell>
											<TableCell align="center">IOF</TableCell>
											<TableCell align="center">Taxa</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<>
											{(proposes?.length === 0 || (!proposes && !isLoading)) && (
												<div className="px-4 py-2 opacity-70 text-sm">
													Nenhuma proposta encontrada.
												</div>
											)}
											{proposes?.map((propose) => (
												<TableRow key={propose.simulationId}>
													<TableCell align="center">{propose.table}</TableCell>
													<TableCell align="center">{Number(propose?.liquidValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
													<TableCell align="center">{propose.iof}%</TableCell>
													<TableCell align="center">{propose.tax}%</TableCell>
												</TableRow>
											))}
											{isLoading && <TableRowSkeleton columns={4} />}
											</>
										</TableBody>
										</Table>
									</Paper>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isLoading}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isLoading}
									variant="contained"
									className={classes.btnWrapper}
								>
									Simular
									{isLoading && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default FgtsSimulationModal;
